const Oeuvre = [
  {
    path: "elephant",
    name: "Big Boy",
    imgName: '42.jpeg',
    instaLink: '#',
    height : '65',
    selfBackground : true,
    oeuvreType : 1,
  },
  {
    path: "3-heads",
    name: "3 Heads",
    imgName: '20.jpg',
    instaLink: '#',
    height : '33',
    selfBackground : true,
    oeuvreType : 2,
  },
  {
    path: "3-heads-2",
    name: "3 Heads (2)",
    imgName: '21.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 2,
  },
  {
    path: "white-head",
    name: "White Head",
    imgName: '22.jpg',
    instaLink: '#',
    height : '19',
    selfBackground : true,
    oeuvreType : 2,
  },
  {
    path: "black-head",
    name: "Black Head",
    imgName: '23.jpg',
    instaLink: '#',
    height : '28',
    selfBackground : true,
    oeuvreType : 2,
  },
  {
    path: "gold-head",
    name: "Gold Head",
    imgName: '24.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 2,
  },
  {
    path: "lain-boy",
    name: "Lain Boy",
    imgName: '25.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 3,
  },
  {
    path: "lain-boy-2",
    name: "Lain Boy (2)",
    imgName: '26.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 3,
  },
  {
    path: "lain-boy-3",
    name: "Lain Boy (3)",
    imgName: '27.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 3,
  },
  {
    path: "lain-boy-4",
    name: "Lain Boy (4)",
    imgName: '28.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 3,
  },
  {
    path: "lain-boy-5",
    name: "Lain Boy (5)",
    imgName: '29.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 3,
  },
  {
    path: "lain-boy-6",
    name: "Lain Boy (6)",
    imgName: '30.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 3,
  },
  {
    path: "justice",
    name: "Justice",
    imgName: '31.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 4,
  },
  {
    path: "justice-2",
    name: "Justice (2)",
    imgName: '32.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 4,
  },
  {
    path: "set",
    name: "Set",
    imgName: '33.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 999,
  },
  {
    path: "gold-break",
    name: "Gold Break",
    imgName: '34.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 5,
  },
  {
    path: "elegant-plaster",
    name: "Elegant Plaster",
    imgName: '35.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 6,
  },
  {
    path: "elegant-plaster-2",
    name: "Elegant Plaster (2)",
    imgName: '36.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 6,
  },
  {
    path: "elegant-plaster-3",
    name: "Elegant Plaster (3)",
    imgName: '37.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 6,
  },
  {
    path: "seated-girl",
    name: "Seated Girl",
    imgName: '38.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 7,
  },
  {
    path: "nature-girl",
    name: "Nature Girl",
    imgName: '39.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 8,
  },
  {
    path: "nature-girl-2",
    name: "Nature Girl (2)",
    imgName: '40.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 8,
  },
  {
    path: "nature-girl-3",
    name: "Nature Girl (3)",
    imgName: '41.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
    oeuvreType : 8,
  }
];

/*const Oeuvre = [
  {
    path: "strength",
    name: "strength",
    imgName: '15.jpg',
    instaLink: '#',
    height : '33',
    selfBackground : true,
  },
  {
    path: "bear",
    name: "bear",
    imgName: '9.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
  },
  {
    path: "the-heads",
    name: "the heads",
    imgName: '10.jpg',
    instaLink: '#',
    height : '33',
    selfBackground : true,
  },
  {
    path: "the-burnt-sun-back",
    name: "The burnt sun back",
    imgName: '16.jpg',
    instaLink: '#',
    height : '19',
    selfBackground : true,
  },
  {
    path: "the-burnt-sun",
    name: "The burnt sun",
    imgName: '17.jpg',
    instaLink: '#',
    height : '28',
    selfBackground : true,
  },
  {
    path: "bear-2",
    name: "Bear 2",
    imgName: '18.jpg',
    instaLink: '#',
    height : '22',
    selfBackground : true,
  }
];*/

export default Oeuvre;

