import m from "mithril";
import menuComponent from "../component/menu"

const Bio = {
  getBio: () => {
    const text = `
    <h2>BIO</h2>
    <span class="para__enter">A</span>llel Driss n’est pas né un crayon à la main. Mais dans le quartier vivant et coloré de Barbès à Paris où il a grandi avec ses deux frères. Son imagination y a rapidement pris le pouvoir : il passait plus de temps à rêver qu’à écouter ses professeurs, sauf au Judo discipline fondatrice qu’il a pratiqué à haut niveau.
    <div></div>
    C'est en entrant à dix-huit ans dans une école d’art qu’il s’autorise enfin à prendre le crayon et exprimer sa passion pour le dessin, l’illustration et le graphisme. Petit à petit, il apprend à maîtriser ces arts, comme la photographie et la direction artistique. Il en fera sa carrière au sein de prestigieux magazines grand public.
    <div></div>
    Ces dernières années, il s’est pris de passion pour la sculpture. Il la pratique, patiemment et ardemment, dans les ateliers d’artistes réputés (celui du sculpteur Jean-Charles Mainardis dans 20ème arrondissement, celui Thierry Benenati à Fontenay aux Roses). Les modèles vivants l’homme, la femme l’inspirent. Les corps, le mouvement comme la pause, les attitudes, les expressions deviennent son champs de recherches spatiales. S’ouvrent à lui les perspectives tridimensionnelles de la sculpture et un rapport physique avec la matière. Il travaille la terre, le plâtre, la résine, avec enthousiasme et une exigence qui le fait ne garder que très peu de pièces de ses réalisations. De ses mains fortes et sensibles, de son regard bienveillant et doux naissent des êtres délicats et énigmatiques qu’il recouvre parfois de feuille d’or comme pour les déïfier.
    <div></div>
    Plus récemment, la nature et certains animaux, symboles de puissance tranquille, éléphant, cheval, hibou… ont rejoint son inventaire.
    </br>
    Au fil des mois, sa technique évolue, mais son style reste.
    </br>
    Quelques tirages d’édition de bronze ancrent ses représentations.
    </br>
    Parmi ses références, il cite les plus grands, les frères Giacometti, Maillol, Boldi, Dugatti, Brancusi, Pompon, Bassompierre.
    `
    return m.trust(text);
  },
  view: (attrs) => {
    return m('section', {id: 'sec-1', class:'bio'},[
      m('div', {class: 'scroll-container'}, [
        m('div', {class: 'header'}, [
          m('span', {class: 'perspective-rect'}),
          m('div', m('h1', 'Allel Driss')),
          m('span', {class: 'perspective-rect'}),
          m('div', {class:'social-nav'}, [
            m('ul', [
              m('li', [
                m('a', {href: 'https://www.instagram.com/alleldriss/', target:"blank_"}, m('i', {class:'fab fa-instagram'})),
              ])
            ])
          ]),
          m(menuComponent),
          m('div', {class:"hooked-data"}, [
            m('div', {class:""}, [
              m('span', 'Scuplteur' )
            ]),
          ]),
        ]),
        m('div', {class: 'main-centered'}, [
          m('div', {class:"text-container"}, [
            Bio.getBio()
          ])
        ]),
        m("div", {class:"bg-container"}, [
          m("div", {class: 'bg-image', 'style': { 'background-image' : 'url(\"img/7.jpg\")'}}),
          m("div", {class: 'black-voile'}),
        ])
      ])
    ])
  }
};

export default Bio;
