import imagesLoaded from 'imagesloaded';
import m from "mithril";

// Map number x from range [a, b] to [c, d]
const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

const calcWinsize = () => {
    return {width: window.innerWidth, height: window.innerHeight};
};

const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

// Gets the mouse position
const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    }
    else if (e.clientX || e.clientY)    {
        posx = e.clientX + body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + body.scrollTop + document.documentElement.scrollTop;
    }
    
    return { x : posx, y : posy }
};

// Preload images
const preloadImages = (selector) => {
    return new Promise((resolve, reject) => imagesLoaded(document.querySelectorAll(selector), resolve));
};

// GetSplitedTextHtml
const getInlineSplited = (text, classSelector = 'defaut-inline') => {
    let textSplited = text.split('')
    return m('div', classSelector == '' ? null : {class: classSelector}, [
        textSplited.map((letter) => m('span', letter ))
    ])
};

export { map, lerp, calcWinsize, getRandomNumber, getMousePos, preloadImages, getInlineSplited };